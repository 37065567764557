import { eppayFetch } from '@eppay/utils/fetcher';
import { API_AUTH_URL } from '../const';

export const postPhoneCertificateNumber = async ({ phoneNumber }: { phoneNumber: string }) => {
  return await eppayFetch<{
    isDormant: null;
    notFoundUser: null;
    selfAuth: boolean;
    sendTrueOrFalse: boolean;
  }>(`${API_AUTH_URL}/auth/certification/number`, {
    method: 'POST',
    body: { phoneNumber: phoneNumber },
    noAuth: true,
  });
};

export const postRequestAccessToken = async ({
  phoneNumber,
  certificationNumber,
}: {
  phoneNumber: string;
  certificationNumber: string;
}) => {
  return await eppayFetch<{
    id: string;
    accessToken: string;
    refreshToken: string;
  }>(`${API_AUTH_URL}/auth/signin/customer`, {
    method: 'POST',
    body: { phoneNumber: phoneNumber, certificationNumber: certificationNumber },
    noAuth: true,
  });
};
