'use client';

import { postPhoneCertificateNumber, postRequestAccessToken } from '@eppay/api/auth';
import { Box, Button, TextField, FormControl, FormLabel } from '@episode-ui';
import { BaseSyntheticEvent, useEffect, useState } from 'react';
import { default as Cookies } from 'js-cookie';
import { COOKIE_AUTHKEY } from '@eppay/const';
import { useRouter } from 'next/navigation';
import nativeutil from '@eppay/utils/nativeutil';

const LoginForm = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [certificationNumber, setCertificationNumber] = useState('');
  const [isActiveCertificate, setIsActiveCertificate] = useState(false);
  const [result, setResult] = useState({});
  const router = useRouter();

  const handleRequestCertificationNumber = async () => {
    const response = await postPhoneCertificateNumber({ phoneNumber: phoneNumber });
    response.status == 200 && response?.body?.sendTrueOrFalse && setIsActiveCertificate(true);
    if (response.status != 200) {
      alert('잘못된 유저 정보입니다.');
    }
  };

  const handleRequestAccessToken = async () => {
    const response = await postRequestAccessToken({
      phoneNumber: phoneNumber,
      certificationNumber: certificationNumber,
    });

    if (response.status == 200) {
      const result = response.body;
      const oneHour = 1 * 60 * 60 * 1000;

      if (result) {
        Cookies.set(COOKIE_AUTHKEY, result.accessToken, { expires: new Date().getTime() + oneHour });

        setResult(result);
        setTimeout(() => {
          nativeutil.back(router);
        }, 400);
      }
    }
  };

  useEffect(() => {}, []);

  return (
    <Box sx={{ padding: 2, flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <FormControl sx={{ width: '100%' }}>
          <FormLabel>핸드폰 번호</FormLabel>
          <TextField type={'number'} onChange={(e: BaseSyntheticEvent) => e.target && setPhoneNumber(e.target.value)} />
        </FormControl>
      </Box>
      {isActiveCertificate && (
        <FormControl sx={{ mt: 2, width: '100%' }}>
          <FormLabel>인증번호</FormLabel>
          <TextField onChange={(e: BaseSyntheticEvent) => e.target && setCertificationNumber(e.target.value)} />
        </FormControl>
      )}
      <Box sx={{ mt: 2, display: 'flex', width: '100%' }}>
        {!isActiveCertificate && <Button onClick={handleRequestCertificationNumber}>인증번호 받기</Button>}
        {isActiveCertificate && <Button onClick={handleRequestAccessToken}>로그인</Button>}
      </Box>
      <Box
        sx={{ mt: 2, background: '#eee', display: 'flex', minHeight: 150, border: '1px dashed red', whiteSpace: 'pre' }}
      >
        {JSON.stringify(result, null, 2)}
      </Box>
    </Box>
  );
};

// request number
// https://sapi.epsd.co.kr/auth/certification/number { "phoneNumber":"01012341234" }
// request token
// https://sapi.epsd.co.kr/auth/signin/customer {"phoneNumber":"01012341234","certificationNumber": "953465" }

export default LoginForm;
