import { APP_DOMAIN } from '@eppay/api/const';
import { COOKIE_AUTHKEY } from '@eppay/const';
import { redirect } from 'next/navigation';

export const sendSlackErrorReport = async (payload: any) => {
  const response = await fetch(`${APP_DOMAIN}/api-server/report`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  return response;
};

export const eppayFetch = async <T extends any>(
  url: RequestInfo | URL,
  options?: Omit<RequestInit, 'body'> & { body?: any; noAuth?: boolean; noUser?: boolean },
  cache?: RequestCache,
): Promise<{ status: number; body?: T } | never> => {
  try {
    const accessToken = await getAuthToken();
    if (!options?.noAuth && !accessToken) {
      console.error('accessToken 없음. 인증 오류.');
      // await sendSlackErrorReport({ text: `[401] API Token Error [${url}]: ${JSON.stringify(options)}` });
      redirect('/login-error?message=noaccesstoken');
    }

    const t1 = performance.now();
    const response = await fetch(url, {
      method: options?.method || 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...{ ...(!options?.noAuth && { 'X-Access-Token': accessToken }) },
        ...{ ...options?.headers },
      },
      // redirect: 'follow',
      ...(options && options.body && { body: JSON.stringify(options.body) }),
      ...(cache && { cache: cache }),
      ...(options && options.next && { next: { ...options.next } }),
    });
    const t2 = performance.now();
    const delay = t2 - t1;

    if (delay > 500) {
      console.info(`API Request Delay ${url} takes ${t2 - t1}ms`);
      // await sendSlackErrorReport({ text: `API Request Delay ${url} takes ${t2 - t1}ms` });
    }

    if (!response.ok) {
      // await sendSlackErrorReport({ text: `[${response.status}] API Request Error [${url}]` });
      const json = await response.json();
      if (json.code === '002') {
        //return redirect('/login-error?message=noaccesstoken');
        return Promise.resolve(json);
      } else {
        return Promise.reject(json);
      }
    }
    try {
      const body = await response.json();
      return Promise.resolve({
        status: response.status,
        body,
      });
    } catch {
      return Promise.resolve({
        status: response.status,
      });
    }
  } catch (e: any) {
    // await sendSlackErrorReport({ text: `[500] API Fetcher Server Error [${url}]: ${JSON.stringify(e)}` });
    return Promise.reject(e);
  }
};

export const isServer = typeof window === 'undefined';

export const getAuthToken = async () => {
  let token;

  if (isServer) {
    const { cookies: serverCookies } = await import('next/headers');
    token = serverCookies().get(COOKIE_AUTHKEY)?.value;
  } else {
    const { default: clientCookies } = await import('js-cookie');
    token = clientCookies.get(COOKIE_AUTHKEY);
  }
  return token;
};

export const getCustomerId = async () => {
  let token;

  if (isServer) {
    const { cookies: serverCookies } = await import('next/headers');
    token = serverCookies().get('customerId')?.value;
  } else {
    const { default: Cookies } = await import('js-cookie');
    token = Cookies.get('customerId');
  }
  return token;
};
