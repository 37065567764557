'use client';

import { BackButtonHeader, Typography } from '@episode-ui';
import LoginForm from './(client)/LoginForm';
// import { useRouter } from 'next/navigation';
// const app_env = process.env.APP_ENV;

const LoginPage = () => {
  // const router = useRouter();
  // if (app_env != 'LOCAL' && app_env != 'DEVELOPMENT' && app_env != 'STAGING') {
  //   router.replace('/not-found');
  // }

  return (
    <>
      <BackButtonHeader>
        <Typography
          variant="body1_bold"
          sx={{
            display: 'inline-block',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          로그인
        </Typography>
      </BackButtonHeader>
      <LoginForm />
    </>
  );
};

export default LoginPage;
